import {
  SET_SENSING_DATA,
  READ_FARM_IMAGES_DATES,
  READ_FARM_VALVES_DETAILS,
  VALVE_REFRESH_MAP,
  READ_SENSING_CHARTS,
  READ_VALVES_WITH_FILTER,
  READ_ALL_VALVE_WITH_FARM_ID,
  GEO_VALVE,
  READ_VALVES_BY_FIELD_ID,
  SHOW_COLORS,
  CLEAR_VALVES,
} from "../../types/tree/sensing";
import { get } from "@/redux/crud/get";
import { post } from "@/redux/crud/post";

export const setSensingData = (payload: any) => (dispatch: any) => {
  dispatch({ type: SET_SENSING_DATA, payload });
};

export const readFarmImagesDates = (id: string = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farm-images/dates/" + id + "/",
    title: "SENSING",
    successType: READ_FARM_IMAGES_DATES,
  });
export const readValvesWithFilter = (object_id) =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `water-network-items/?object_id=${object_id}&content_type__model=treefieldinfo&entity_type_id__prefix=VLV-`,
    title: "Tree",
    successType: READ_VALVES_WITH_FILTER,
  });
export const readFarmImagesDatesIrriwatch = (id: string = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "irriwatch-logs/farm-dates/" + id,
    title: "SENSING",
    successType: READ_FARM_IMAGES_DATES,
  });
export const readFarmValvesDetails = (id: string = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farms/pivots-details/" + id + "/",
    title: "SENSING",
    successType: READ_FARM_VALVES_DETAILS,
  });
export const creatCluster = (formData) =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "clusters/",
    formData,
    title: "cluster",
  });

export const setValveRefreshMap = () => (dispatch: any) => {
  dispatch({
    type: VALVE_REFRESH_MAP,
    payload: false,
  });
  setTimeout(() => dispatch({ type: VALVE_REFRESH_MAP, payload: true }), 100);
};
export const setShowColors = (payload) => (dispatch) => {
  dispatch({ type: SHOW_COLORS, payload });
};

// sensing charts
// settillite Irrigation charts
export const readSensingCharts = (
  valve_id: string | number = "",
  category: string,
  data = "season"
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `water-network-items/${valve_id}/irrigation_details_nodates/${category}/${data}/`,
    title: "SENSING",
    successType: READ_SENSING_CHARTS,
  });

export const readaAllValvesWithFarmId = (id): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/${id}/valves-served-area/`,
    title: "valvesWithFarmId",
    successType: READ_ALL_VALVE_WITH_FARM_ID,
  });

export const readValvesByFieldId = (id) =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/valve-area/${id}/`,
    title: "Asset",
    successType: READ_VALVES_BY_FIELD_ID,
  });

export const clearValves = () => (dispatch: any) => {
  dispatch({ type: CLEAR_VALVES });
};
