import { get } from "@/redux/crud/get";
import { patch } from "@/redux/crud/patch";
import { post } from "../../crud/post";
import {
  CLEAR_FIELD,
  CLEAR_GEO_FIELD,
  READ_GEO_FIELDS,
  READ_IRRIGATION_FIELDS,
  READ_ONE_FIELD,
  SET_FIELD_DATA,
  CLEAR_IRRIGATION_FIELDS,
  FIELDS_PAGINATION,
  READ_WATER_TREE_CHART,
  READ_IRR_LAYERS_TREE_FIELDS,
  CLEAR_WATER_TREE_CHART,
  READ_ONE_FIELD_DETAILS,
  READ_IRRIGATION_FAULTY_DRIPS,
  READ_LOGS,
  READ_ADVANCED_IRRIGATION_FIELD,
} from "../../types/tree/fields";

export const setTreeFieldData = (payload: any) => (dispatch: any) => {
  dispatch({ type: SET_FIELD_DATA, payload });
};

export const readTreeFieldsX = (query = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `tree-fields/${query}`,
    title: "Field",
  });
export const readTreeFields = (query = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `tree-fields/${query}`,
    title: "Field",
  });
export const readTreeShowFields = (org_id, query = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/show/tree/${org_id}/`,
    title: "Field",
  });
export const readTreeWaterChartLog = (
  id: number | string = 1,
  category: string,
  startDate: string,
  endDate: string
): any =>
  get({
    url:
      category === "area_percentage_requiring_irrigation"
        ? process.env.NEXT_PUBLIC_BASE_URL +
          `water-network-items/${id}/irrigation_details_nodates/area_percentage_requiring_irrigation/`
        : category === "evapotranspiration"
        ? process.env.NEXT_PUBLIC_BASE_URL +
          `water-network-items/${id}/irrigation_details_nodates/evapotranspiration/`
        : process.env.NEXT_PUBLIC_BASE_URL +
          `water-network-items/${id}/irrigation_details_nodates/${category}/`,
    title: "Pivot_Field",
    successType: READ_WATER_TREE_CHART,
  });
export const readOneField = (id: number | string): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "tree-fields/" + id + "/",
    title: "Field",
    successType: READ_ONE_FIELD,
  });
export const readOneFieldDetails = (id: string | string): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "tree-fields/" + id,
    title: "Field",
    successType: READ_ONE_FIELD_DETAILS,
  });
export const readIrrLayersTreeFields = (
  farm_id: string = "",
  title: string = ""
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL + `farms/${farm_id}/layers/10/${title}/`,

    title: "Field",
    successType: READ_IRR_LAYERS_TREE_FIELDS,
  });
export const readTreeGeoFields = (id: number | string): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/${id}/gboundaries/`,
    title: "Field",
    successType: READ_GEO_FIELDS,
  });

export const readIrrigationFields = (
  farm_id: string | number,
  startDate: string = new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 6)
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-"),
  endDate: string = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-")
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `farms/${farm_id}/tree-irrigation/${startDate}/${endDate}/`,
    title: "Field",
    successType: READ_IRRIGATION_FIELDS,
  });

export const readIrrigationFaultyDrips = (farm_id: string | number): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `trees/faulty-drips/${farm_id}/`,
    title: "Field",
    successType: READ_IRRIGATION_FAULTY_DRIPS,
  });

export const creatField = (formData: any): any =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "tree-fields/",
    title: "Field",
    formData,
  });

export const creatTreeField = (formData: any): any =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "trees/generate-trees/",
    title: "TreeField",
    formData,
  });

export const updateField = (formData: any): any =>
  patch({
    url: process.env.NEXT_PUBLIC_BASE_URL + "tree-fields/" + formData.id + "/",
    title: "Field",
    formData,
  });

export const readLogs = (id: number | string): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `treeagro-activity-logs/advanced-details/${id}/`,
    title: "Pivot_Field",
    successType: READ_LOGS,
  });

export const readTreeFieldAdvancedIrrigationStatus = (
  farm_id: number | string
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `farms/${farm_id}/advanced-irrigation-tree-fields/`,
    title: "tree_Field_advanced_irrigation",
    successType: READ_ADVANCED_IRRIGATION_FIELD,
  });

export const clearField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_FIELD });
};
export const clearGeoField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_GEO_FIELD });
};
export const clearIrrigationFields = () => (dispatch: any) => {
  dispatch({ type: CLEAR_IRRIGATION_FIELDS });
};
export const clearWaterTreeChart = () => (dispatch: any) => {
  dispatch({ type: CLEAR_WATER_TREE_CHART });
};
